.page_404 {

  padding: 40px 0;
  // background: #fff;

  .page_404 img {
    width: 100%;
  }

    .four_zero_four_bg {
      background-image: url("../../assets/img/404.gif");
      height: 400px;
      background-position: center;
      background-size: cover;  
    
      .four_zero_four_bg h3 {
        font-size: 80px;
      }
    }

    .link_404 {
      color: #fff !important;
      padding: 10px 20px;
      background: #000000;
      margin: 0 0;
      display: inline-block;
      border-radius: 12px;
      min-width: 200px;
      border:none
    }

  .contant_box_404 {}
}