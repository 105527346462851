* {
    box-sizing: border-box;
    font-family: 'General Sans' !important;
}

.hide{
    display: none !important;
}
.hidden{
    visibility: hidden !important;
}







.questionnaire-block {
    /* height: 100vh; */
    /* display: grid; */
    /* max-height:110vh;
    overflow: scroll; */
    
    padding: 0.5rem 1rem;
    align-items: center;
    margin-top: 5rem;
    margin-bottom: 80px;
}

.question-block {
    opacity: 1;
    transition: opacity 0.5s ease-in-out;
}

.prev-question{
    border:none;
    color: black;
    display: flex;
    align-items: center;
    padding-left: 0px;
}

.prev-question:active,
.prev-question:focus-visible,
.prev-question:hover {
    color: black !important;
    background-color: transparent !important;
    border-color: transparent !important;
    box-shadow:none !important;
}

.terminate-button{
    border:none;
    color: black;
    display: flex;
    align-items: center;
    padding-left: 10px;
    padding-right: 0px;
}

.terminate-button:active,
.terminate-button:hover,
.prev-question:focus-visible,
.prev-question:hover {
    color: black !important;
    background-color: transparent !important;
    border-color: transparent !important;
    box-shadow:none !important;
}

.svg-inline--fa {
    height: 20px
}


.question-options-icon{
    width: 30px;
    height: 30px;
    margin-right: 10px;
}

.option-label-icon{
    width: 20px;
    height: 20px;
    margin-right: 5px;
}

.fade-out {
    opacity: 0;
    pointer-events: none;
    
    /* position: relative;
    padding: 2rem;
    top: 0;
    left:0;
    width: 100%; */
}

.fade-in {
    opacity: 1; 

    /* position: absolute;
    padding: 2rem;
    top: 0;
    left:0;
    width: 100%; */
}

/* Animation Div */
.cssanimation{
    animation-duration: 0.5s;
    animation-fill-mode: both;
}

.fadeInBottom { animation-name: fadeInBottom }

@keyframes fadeInBottom {
    from {
        opacity: 0;
        transform: translateY(100%);
    }
    to { opacity: 1 }
}
  

/*  */
.preloader-section{
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: grid;
    align-items: center;
    justify-content: center;
    /* background-color: #ffffff; */
    z-index: 99999;
}

.preloader-section > div{
    display: flex;
    flex-direction: column;
    justify-content: center;
}

/* .preloader-section img{
  max-width: 500px;
  width: 267.5px;
  height: 267.5px;
  animation-play-state: paused;
} */

.loading-text {
    font-family: 'General Sans' !important;
    font-size: 18px;
    font-weight: 500;
    line-height: 25px;
    letter-spacing: 0em;
    text-align: center;
    color:#545463;
    margin-top:1rem;
    min-height: 25px;
}

.loading-text span {
    font-weight: 500;
    color:#32323B;
    /* color:#0053C7; */
}
.preloader-section .image-section{
    display: flex;
    justify-content: center;
    align-items: center;
}

.preloader-text{
    font-family: 'General Sans' !important;
    font-size: 16px;
    font-weight: 500;
    line-height: 19px;
    letter-spacing: 0em;
    text-align: center; 
    color:#E58600;
    position: absolute;
    left: 0;
    right: 0;
    width: 100px;
    margin: auto;
    background: #f8e9c0;
}

.time-picker-input::-webkit-calendar-picker-indicator {
    width: 100%;
    position: fixed;
    opacity: 0;
}

/* IOS Alert */
.alert-overlay{
    background-color: #00000080;
    width: 100%;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 9;
}

.loader-overlay{
    width: 100%;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 0;
    background-color: #ffffff;
}

.ios-alert .alert {
	position: fixed;
	top: 50%;
	left: 50%;
	margin-left: -135px;
	margin-top: -50px;
	width: 270px;
	text-align: center;
	font-family: -apple-system, SF UI Text, Helvetica Neue, Helvetica, Arial, sans-serif !important;
	font-size: 14px;
	line-height: 1.4;
	border-radius: 13px;
	overflow: hidden;
	z-index: 99;
	background-color: #d7d4d4;
    padding: 0;
}


.android-alert .alert {
	position: fixed;
	top: 50%;
	left: 50%;
	margin-left: -135px;
	margin-top: -50px;
	width: 270px;
	text-align: center;
	font-family: 'Roboto', sans-serif !important;
	font-size: 14px;
	line-height: 1.4;
	border-radius: 13px;
	overflow: hidden;
	z-index: 99;
	background-color: #ECE6F0;
    padding: 0;
}


.ios-alert .alert .inner {
	padding: 15px;
}

.android-alert .alert .inner {
	padding: 20px;
}

.ios-alert .alert .title {
    font-size: 17px;
    font-weight: 590;
    line-height: 22px;
    letter-spacing: -0.4000000059604645px;
    text-align: center;
}

.android-alert .alert .title {
    font-family: 'Roboto' !important;
    font-size: 24px;
    font-weight: 400;
    line-height: 32px;
    letter-spacing: 0em;
    text-align: left;
    margin-bottom: 0.8rem;
}

.ios-alert .alert .text {
    margin-top: 5px;
    font-size: 13px;
    font-weight: 400;
    line-height: 18px;
    letter-spacing: -0.4000000059604645px;
    text-align: center;
}

.android-alert .alert .text {
    margin-top: 5px;
    font-size: 13px;
    font-weight: 400;
    line-height: 18px;
    letter-spacing: -0.4000000059604645px;
    text-align: left;
}

.ios-alert .alert .button {
	position: relative;
	height: 44px;
	line-height: 44px;
	font-size: 17px;
	color: #007aff;
	border-radius: 0 0 13px 13px;
	overflow: hidden;
	cursor: pointer;

    display: flex;
}

.android-alert .alert .button {
    display: flex;
    justify-content: flex-end;
    padding: 20px;
    padding-top: 0.5rem;
}

.ios-alert .alert .button>div{
    width: 50%;
}

.android-alert .alert .button>div {
    font-family: 'Roboto' !important;
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: 0.10000000149011612px;
    text-align: center;

    padding: 0 1rem;
    color: #6750A4;
}

.ios-alert .alert .button>div:first-child{
    border-right: 1px solid #c4c4c4;
}

.ios-alert .alert .button:after {
	content: '';
	position: absolute;
	left: 0;
	top: 0;
	height: 1px;
	width: 100%;
	display: block;
	background-color: #c4c4c4;
	z-index: 9;
}

/* Quit Loader */
.loading-bar-spinner.spinner {
    left: 50%;
    margin-left: -20px;
    top: 48%;
    margin-top: -20px;
    position: fixed;
    z-index: 19 !important;
    -webkit-animation: loading-bar-spinner 400ms linear infinite;
    animation: loading-bar-spinner 400ms linear infinite;
}

.loading-bar-spinner.spinner .spinner-icon {
    width: 40px;
    height: 40px;
    border: solid 4px transparent;
    border-top-color: #00ffe2 !important;
    border-left-color: #00ffe2 !important;
    border-radius: 50%;
}

@-webkit-keyframes loading-bar-spinner {
    0% {
        transform: rotate(0deg);
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

@keyframes loading-bar-spinner {
    0% {
        transform: rotate(0deg);
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

/* ios spinner*/
  .spinner {
    font-size: 30px;
    position: relative;
    display: inline-block;
    width: 1em;
    height: 1em;
  }

  .loader.center svg,
  .spinner.center {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    margin: auto;
  }

  .loader.center svg{
    width: 90% !important;
    max-width: 280px;
  }
  
  .spinner-blade {
    position: absolute;
    left: 0.4629em;
    bottom: 0;
    width: 5px;
    height: 12px;
    border-radius: 0.5em;
    background-color: transparent;
    transform-origin: center -0.2222em;
    -webkit-animation: spinner-fade 1s infinite linear;
            animation: spinner-fade 1s infinite linear;
  }

  .spinner-blade:nth-child(1) {
    -webkit-animation-delay: 0s;
            animation-delay: 0s;
    transform: rotate(0deg);
  }
  .spinner-blade:nth-child(2) {
    -webkit-animation-delay: 0.125s;
            animation-delay: 0.125s;
    transform: rotate(45deg);
  }
  .spinner-blade:nth-child(3) {
    -webkit-animation-delay: 0.250s;
            animation-delay: 0.250s;
    transform: rotate(90deg);
  }
  .spinner-blade:nth-child(4) {
    -webkit-animation-delay: 0.375s;
            animation-delay: 0.375s;
    transform: rotate(135deg);
  }
  .spinner-blade:nth-child(5) {
    -webkit-animation-delay: 0.500s;
            animation-delay: 0.500s;
    transform: rotate(180deg);
  }
  .spinner-blade:nth-child(6) {
    -webkit-animation-delay: 0.625s;
            animation-delay: 0.625s;
    transform: rotate(225deg);
  }
  .spinner-blade:nth-child(7) {
    -webkit-animation-delay: 0.750s;
            animation-delay: 0.750s;
    transform: rotate(270deg);
  }
  .spinner-blade:nth-child(8) {
    -webkit-animation-delay: 0.825s;
            animation-delay: 0.825s;
    transform: rotate(315deg);
   }
  @-webkit-keyframes spinner-fade {
    0% {
      background-color: #0053C7;
    }
    100% {
      background-color: transparent;
    }
  }
  
  @keyframes spinner-fade {
    0% {
      background-color: #0053C7;
    }
    100% {
      background-color: transparent;
    }
  }

  
.questionnaire-header.score {
    position: inherit;
    padding: 2rem 0.8rem 0 !important;
}

.score-snapshot .questionnaire-header {
    padding: 2rem 0rem;
}

.score-snapshot .scorecard-section {
    padding: 0rem 0.8rem !important;
}