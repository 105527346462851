.main-component {
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    max-width: 1040px;
    margin: auto;
}

.loader-bg{
    position: absolute;
    z-index: 99999;
    height: 100%;
    width:100%;
    top: 0;
    background-color: #00000099;
}


.verification-loader {
    position: absolute;
    top: 0;
    left: 0;
    background-color: #ffffffa1;
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 999;


    .circle-loader {
        margin: 0 0 30px 10px;
        border: 10px solid rgba(0, 0, 0, 0.2);        
        border-left-color: #5CA423;        
        animation-name: loader-spin;
        animation-duration: 1s;
        animation-iteration-count: infinite;
        animation-timing-function: linear;
        position: relative;
        display: inline-block;
        vertical-align: top;

    }

    .circle-loader,
    .circle-loader:after {
        border-radius: 50%;
        width: 8em;
        height: 8em;
        
    }
    .success {
        -webkit-animation: none;
        animation: none;
        background-color: #D3F1BC;
        border-color: #D3F1BC;
        transition: border opacity 500ms ease-out;
    }

    .success .status.draw:after {
        animation-duration: 1.2s;
        animation-timing-function: ease;
        animation-name: checkmark;
        transform: scaleX(-1) rotate(135deg);
    }

    .success .status:after {
        opacity: 1;
        height: 4em;
        width: 2em;
        transform-origin: left top;
        border-right: 12px solid #5CA423;
        border-top: 12px solid #5CA423;
        content: "";
        top: 55%;
        left: 20%;
        position: absolute;
    }

    .failed {
        -webkit-animation: none;
        animation: none;
        border-color: #f1bcbc;
        background-color: #f1bcbc;
        transition: border opacity 500ms ease-out;
    }

    .failed .status {
        top: 50%;
        left: 50%;
        position: absolute;
    }

    .failed .status.draw:before,
    .failed .status.draw:after {
        animation-duration: 1.2s;
        animation-timing-function: ease;
        animation-name: crossmark;
    }

    .failed .status.draw:before {
        transform: scaleX(-1) rotate(45deg);
    }

    .failed .status.draw:after {
        transform: scaleX(-1) rotate(225deg);
    }

    .failed .status:before,
    .failed .status:after {
        opacity: 1;
        height: 2em;
        width: 2em;
        transform-origin: left top;
        border-right: 12px solid #FF0000;
        border-top: 12px solid #FF0000;
        content: "";
        position: absolute;
    }

    .failed .status:before {
        left: calc(1.656854259em - 15px);
        top: calc(4px - 1.656854259em);
    }

    .failed .status:after {
        left: calc(10.656854259px - 1.656854259em);
        top: calc(1.656854259em - 2.8284271295px);
    }

    @keyframes loader-spin {
        0% {
            transform: rotate(0deg);
        }

        100% {
            transform: rotate(360deg);
        }
    }

    @keyframes checkmark {
        0% {
            height: 0;
            width: 0;
            opacity: 1;
        }

        20% {
            height: 0;
            width: 2em;
            opacity: 1;
        }

        40% {
            height: 4em;
            width: 2em;
            opacity: 1;
        }

        100% {
            height: 4em;
            width: 2em;
            opacity: 1;
        }
    }

    @keyframes crossmark {
        0% {
            height: 0;
            width: 0;
            opacity: 1;
        }

        20% {
            height: 0;
            width: 2em;
            opacity: 1;
        }

        40% {
            height: 2em;
            width: 2em;
            opacity: 1;
        }

        100% {
            height: 2em;
            width: 2em;
            opacity: 1;
        }
    }


}

.footer-section{
    max-width: 100%;
    display: flex;
    justify-content: space-between;
    margin: 1.5rem auto 0rem auto;
    align-items: center;
    width: 100%;
    .terms{
        a{
            font-size: 14px;
            font-weight: 400;
            line-height: 21px;
            letter-spacing: 0px;
            text-align: left;
            color: #0E0E11;
            text-decoration: none;
            margin-right: 1.5rem;
        }
    }
    .copyright{
        font-size: 14px;
        font-weight: 400;
        line-height: 21px;
        letter-spacing: 0px;
        text-align: left;
        color: #0E0E11;
        text-decoration: none; 
    }
}

@media only screen and (max-width: 768px) {
    .main-component {
        // height: unset;
        height: calc(100vh - 70px);
    }
    .footer-section{
        max-width: 100%;
        display: none;    
        // position: absolute;
        // bottom: 0;
        // width: 100%;
        // left: 0;

        .copyright{
            margin-top: 0.5rem;
            margin-bottom: 0.5rem;
            text-align: center;
        }
    }
}