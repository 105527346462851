@import url('./assets/css/fonts.css');
@import url('https://fonts.googleapis.com/css2?family=Protest+Riot&display=swap');

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

/* .App-header,
.App-footer {
  border: 2px solid #f3f5f8;
  background: linear-gradient(45deg, #5fd795, transparent);
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: calc(10px + 2vmin);
  color: white;
  border-radius: 10px;
  padding: 0 10px;
} */

.App-footer {
  /* position: fixed; */
  bottom: 0;
  width: 100%;
  margin-top: 20px;
}

.App-link {
  color: #61dafb;
}


@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.hide {
  display: none;
}

.preloader-section {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: grid;
  align-items: center;
  justify-content: center;
  background-color: #97979766;
  z-index: 9999;
}

.preloader-section img {
  max-width: 50%;
  margin: auto;
}

.preloader-section img.otp-loader {
  max-width: 146px;
  margin: auto;
}



@media only screen and (max-width: 480px) {
  .Toastify__toast{
    z-index: 9999;
  }
  .Toastify__toast-container {
    width: 75vw !important;
    right: 0 !important;
    left: auto !important;
  }
}